export enum PaymentMethodType {
  points = 'ps',
  eWallet = 'ew',
  creditCard = 'cc',
  bankAccount = 'db',
  couponCode = 'cp',
}

export enum PaymentMethodMappedType {
  ps = 'points',
  ew = 'ewallet',
  cc = 'creditCard',
  db = 'directDebit',
  cp = 'couponCode',
}
